import React, { useEffect, useRef, useState } from "react";
import ContentContainer from "../../atoms/ContentContainer";
import {
  Divider,
  Grid,
  Switch,
  Chip,
  Button,
  Alert,
  IconButton,
} from "@mui/material";
import InputPrimary from "../../atoms/InputPrimary";
import SelectPrimary from "../../atoms/SelectPrimary";
import CustomSelectPrimary from "../../atoms/CustomSelectPrimary/CustomSelectPrimary";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  calculateDistanceBetweenLocations,
  generateRandomString,
} from "../../helpers/utils";
import { chargeTypes } from "../GeneralSettings/constants/additonal-charges.constants";
import axios from "../../config/axios";
import CloseIcon from "@mui/icons-material/Close";
import { getAdminInfo } from "../../helpers/auth";


function DeliverySettings() {
  const [input, setInput] = useState({
    deliveryEnabled: false,
    radius: 1,
    deliveryCharge: 0,
    taxProfile: null,
    exceptionZipcodes: "",
    chargeIncreamentByDistanceEnabled: false,
    maxDistanceForBaseDeliveryCharge: 0,
    increamentPerMiles: 0,
    /*
    {
        id: string,
        city: google map place_id,
        deliveryCharge: number,
        taxProfile: taxProfileId, default: "parent"
    }
    */
    customDeliveryCharges: [],
  });
  const citySearchRefsRef = useRef({});
  const [citySearchRefs, _setCitySearchRefs] = useState({});
  const setCitySearchRefs = (value) => {
    citySearchRefsRef.current = value;
    _setCitySearchRefs(value);
  };

  const [eligibleCities, setElibileCities] = useState([]);
  const [initLoading, setInitLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [taxProfiles, setTaxProfiles] = useState([]);
  const [feedback, setFeedback] = useState({
    isSuccess: false,
    isOpen: false,
    message: "",
  });

  useEffect(() => {
    fetchDeliverySettingsData();
  }, []);

  useEffect(() => {
    Object.keys(citySearchRefs).forEach((key) => {
      if (citySearchRefs[key]) {
        const adminInfo = getAdminInfo();
        const bounds = {
          north: adminInfo.location.coordinates[0] + (input.radius * 1.60934) / 100,
          south: adminInfo.location.coordinates[0] - (input.radius * 1.60934) / 100,
          east: adminInfo.location.coordinates[1] + (input.radius * 1.60934) / 100,
          west: adminInfo.location.coordinates[1] - (input.radius * 1.60934) / 100,
        };
        console.log("Bounds", adminInfo.location.coordinates, bounds);
        const options = {
          bounds,
          componentRestrictions: { country: ["us", "ca"] },
          fields: [
            "address_components",
            "formatted_address",
            "geometry",
            "icon",
            "name",
          ],
          types: ["(cities)"],
          strictBounds: false,
        };
        const autocomplete = new window.google.maps.places.Autocomplete(
          citySearchRefs[key],
          options
        );
        // setAutoCompleteInstances([...autoCompleteInstances, autocomplete]);
        window.google.maps.event.addListener(
          autocomplete,
          "place_changed",
          function () {
            const place = autocomplete.getPlace();
            console.log("Place", place.address_components);
            console.log("Place", place.formatted_address);
            console.log("Place", place.geometry);
            console.log("Place", place.name);

            let includeEverything = false;
            const locality = place.address_components.find(address => address.types.includes('locality')).short_name;
            const country = place.address_components.find(address => address.types.includes('country')).short_name;
            let addressString = place.address_components.reduce((acc, curr) => {
              if (includeEverything) {
                acc += `${curr.short_name},`;
                return acc;
              } else {
                if (curr.types.includes("locality")) {
                  includeEverything = true;
                  acc += `${curr.short_name},`
                  return acc;
                } 
                return acc;
              }
            }, "")
            addressString = addressString.replace(/,\s*$/, "");

            setInput({
              ...input,
              customDeliveryCharges: input.customDeliveryCharges.map(charge => {
                if (charge.id === key) {
                  return {
                    ...charge,
                    city: {
                      formatted_address: place.formatted_address,
                      unique_address_string: addressString
                    }
                  }
                }
                return charge;
              })
            })
          }
        );
      }
    });
  }, [citySearchRefs, input.radius]);

  const handleInputChange = (key, value) => {
    setInput({
      ...input,
      [key]: value,
    });
  };
  const handleRadiusInputChange = (value) => {
    setInput({
      ...input,
      radius: value,
    });
  };
  const handleAddNewCustomDeliveryCharge = () => {
    const newId = generateRandomString(6);
    setInput({
      ...input,
      customDeliveryCharges: [
        ...input.customDeliveryCharges,
        {
          id: newId,
          // isNew: true,
          city: {
            formatted_address: "",
            unique_address_string: ""
          },
          deliveryCharge: input.deliveryCharge ?? 0,
          taxProfile: "parent",
        },
      ],
    });
    setCitySearchRefs({
      ...citySearchRefs,
      [newId]: null,
    });
  };

  const fetchDeliverySettingsData = async () => {
    setInitLoading(true);
    try {
      await fetchTaxProfiles();
      const res = await axios.get("/v1/delivery/get");
      const data = res.data.data;
      setInput({
        ...data,
        exceptionZipcodes: data.exceptionZipcodes.reduce(
          (acc, curr) => (acc !== "" ? `${acc},${curr}` : curr),
          ""
        ),
      });
    } catch (error) {
      setFeedback({
        isOpen: true,
        isSuccess: false,
        message: "Failed to fetch data",
      });
    }
    setInitLoading(false);
  };
  const updateDeliverySettings = async () => {
    const errors = [];
    if (input.deliveryEnabled) {
      if (input.radius <= 0) {
        errors.push("Radius must be higher than zero");
      }
      if (!input.taxProfile) {
        errors.push("Tax profile must be selected");
      }
      if (input.chargeIncreamentByDistanceEnabled) {
        if (!input.increamentPerMiles || input.increamentPerMiles <= 0) {
          errors.push(
            "Increament amount per mile has to be positive and more than zero"
          );
        }
      }
      if (
        !input.customDeliveryCharges.reduce((acc, curr) => {
          if (!curr.city) return false;
          if (!curr.deliveryCharge || curr.deliveryCharge <= 0) return false;
          return true;
        }, true)
      ) {
        errors.push(
          "City and positive delivery charge is mandetory for all Custom delivery charge entries!"
        );
      }
    }

    if (errors.length !== 0) {
      setFeedback({
        isOpen: true,
        isSuccess: false,
        message: (
          <ul>
            {errors.map((err) => (
              <li>{err}</li>
            ))}
          </ul>
        ),
      });
      return;
    }

    setSubmitLoading(true);
    const body = {
      ...input,
      customDeliveryCharges: input.customDeliveryCharges.map(charge => {
        if (charge.taxProfile === "parent") {
          return {
            ...charge,
            taxProfile: input.taxProfile
          }
        }
        return charge;
      }),
      exceptionZipcodes: input.exceptionZipcodes
        .split(",")
        .map((zip) => zip.trim()),
    };
    try {
      await axios.put("/v1/delivery/update", body);
      setFeedback({
        isOpen: true,
        isSuccess: true,
        message: "Delivery settings updated successfully",
      });
    } catch (error) {
      setFeedback({
        isOpen: true,
        isSuccess: false,
        message: "Something went wrong! Failed to update delivery settings",
      });
    }
    setSubmitLoading(false);
  };

  const fetchTaxProfiles = async () => {
    const res = await axios.get("/v1/tax-profile/list");
    setTaxProfiles([
      ...res.data.data.defaultTaxProfiles,
      ...res.data.data.customTaxProfiles
    ]);
  };
  const handleDeleteCharge = (id) => {
    setInput({
      ...input,
      customDeliveryCharges: input.customDeliveryCharges.filter(charge => charge.id !== id)
    });
  }
  return (
    <ContentContainer heading={"Delivery Settings"}>
      <Grid container spacing={3}>
        {feedback.isOpen && (
          <Grid item xs={12}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setFeedback({
                      ...feedback,
                      isOpen: false,
                    });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity={feedback.isSuccess ? "success" : "error"}
            >
              {feedback.message}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Switch
              checked={input.deliveryEnabled}
              onChange={(e) =>
                handleInputChange("deliveryEnabled", e.target.checked)
              }
            />
            <span>Enable/Disable Delivery</span>
          </div>
        </Grid>
        {input.deliveryEnabled && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputPrimary
                // hintChip={<span style={{ margin: '0px 10px' }}>miles</span>}
                label={"Deivery Radius (in miles)"}
                placeholder="Enter delivery radius to allow delivery"
                type="number"
                value={input.radius}
                onChange={(e) => handleRadiusInputChange(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputPrimary
                hintChip={"$"}
                label={"Base Delivery Charge"}
                placeholder="Define your base delivery charge"
                type="number"
                value={input.deliveryCharge}
                onChange={(e) =>
                  handleInputChange("deliveryCharge", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputPrimary
                // hintChip={<span style={{ margin: '0px 10px' }}>miles</span>}
                label={"Enter exception zip codes"}
                placeholder="Enter zip codes where you will not make delivery"
                value={input.exceptionZipcodes}
                onChange={(e) =>
                  handleInputChange("exceptionZipcodes", e.target.value)
                }
              />
              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 5,
                }}
              >
                {input.exceptionZipcodes
                  .split(",")
                  .map((zipCode) => zipCode.trim())
                  .map((zipCode, i) =>
                    zipCode !== "" ? (
                      <Chip key={`${zipCode}_${i}`} label={zipCode} />
                    ) : (
                      ""
                    )
                  )}
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CustomSelectPrimary
                value={input.taxProfile}
                setValue={(value) => handleInputChange("taxProfile", value)}
                label={"Select Tax Profile"}
                inputLabel={"No tax profile selected"}
                options={taxProfiles.map((profile) => ({
                  value: profile._id,
                  label: profile.name,
                }))}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Switch
                  checked={input.chargeIncreamentByDistanceEnabled}
                  onChange={(e) =>
                    handleInputChange(
                      "chargeIncreamentByDistanceEnabled",
                      e.target.checked
                    )
                  }
                />
                <span>Enable charge increament by distance</span>
              </div>
            </Grid>
            {input.chargeIncreamentByDistanceEnabled && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <InputPrimary
                    value={input.maxDistanceForBaseDeliveryCharge}
                    onChange={(e) =>
                      handleInputChange(
                        "maxDistanceForBaseDeliveryCharge",
                        e.target.value
                      )
                    }
                    label="Apply increamental charge after distance (in miles)"
                    type={"number"}
                    placeholder="Input distance in miles"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <InputPrimary
                    value={input.increamentPerMiles}
                    onChange={(e) =>
                      handleInputChange("increamentPerMiles", e.target.value)
                    }
                    label="Increament amount per miles"
                    type={"number"}
                    hintChip={"$"}
                    placeholder="Input increament amount"
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h6>Custom Rate By Cities</h6>
                <Button
                  variant="contained"
                  onClick={handleAddNewCustomDeliveryCharge}
                >
                  Add New Entry
                </Button>
              </div>
            </Grid>
            {input.chargeIncreamentByDistanceEnabled && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  By adding charges by custom city, it will overwrite your
                  specified increament by distance with your specified charge
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <TableContainer style={{ minHeight: 300 }} component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>City</TableCell>
                      <TableCell>Delivery Rate</TableCell>
                      <TableCell>Tax Profile</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {input.customDeliveryCharges.map((charge) => (
                      <TableRow key={charge.id}>
                        <TableCell>
                          {/* <CustomSelectPrimary
                            //   value={input.taxProfile}
                            //   setValue={(value) =>
                            //     handleInputChange("taxProfile", value)
                            //   }
                            value={charge.city}
                            inputLabel={"No city selected"}
                            options={eligibleCities.map((city) => ({
                              label: city.city,
                              value: city.id,
                            }))}
                          /> */}
                          <InputPrimary
                            placeholder="Search cities in defined radius"
                            defaultValue={charge.city.formatted_address}
                            ref={(ref) => {
                              if (!citySearchRefsRef.current[charge.id]) {
                                const _citySearchRefs = {
                                  ...citySearchRefsRef.current,
                                  [charge.id]: ref,
                                };
                                setCitySearchRefs(_citySearchRefs);
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <InputPrimary
                            placeholder="Enter charge"
                            value={charge.deliveryCharge}
                          />
                        </TableCell>
                        <TableCell>
                          <CustomSelectPrimary
                            // value={input.taxProfile}
                            // setValue={(value) =>
                            //   handleInputChange("taxProfile", value)
                            // }
                            value={charge.taxProfile}
                            inputLabel={"No tax profile selected"}
                            options={[
                              { label: "Parent", value: "parent" },
                              ...taxProfiles.map((t) => ({
                                value: t._id,
                                label: t.name,
                              })),
                            ]}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleDeleteCharge(charge.id)} color="error">
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>

                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Button
            variant="contained"
            disabled={submitLoading}
            onClick={updateDeliverySettings}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </ContentContainer>
  );
}

export default DeliverySettings;
