import React from "react";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import { timeDifferenceString } from "../../../helpers/utils";

export default function OrdersTable({
  orders,
  setOrderToDelete,
  backgroundColor,
  checkColor,
  deliveryType,
}) {
  const history = useHistory();
  return (
    <div>
      <table className="table table-striped-cust">
        <thead>
          <tr>
            <th scope="col" style={{ color: "black" }}>
              Order ID
            </th>
            <th scope="col"></th>
            {/* <th scope="col"></th>
                    <th scope="col"></th> */}

            {/* <th scope="col"></th> */}
            <th scope="col"></th>
            <th scope="col" style={{ color: "black" }}>
              Customer
            </th>
            <th scope="col" style={{ color: "black" }}>
              Price
            </th>
            <th scope="col"></th>
            <th scope="col"></th>
            {/* <th scope="col"></th> */}

            <th scope="col" style={{ color: "black" }}>
              {deliveryType === "pickup" ? "Purchased On" : "Delivery At"}
            </th>
            <th scope="col" style={{ color: "black", textAlign: "center" }}>
              Status
            </th>
            <th scope="col" style={{ color: "black", textAlign: "center" }}>
              Payment Method
            </th>

            <th scope="col" style={{ color: "black", textAlign: "center" }}>
              Action
            </th>

            {/* <th scope="col"></th> */}
            {/* <th scope="col"></th> */}
            {/* <th scope="col"></th>
                    <th scope="col"></th> */}
          </tr>
        </thead>
        <tbody>
          {orders
            // .slice((currentPage - 1) * perPage, currentPage * perPage)
            .map((inv, i) => {
              return (
                <tr style={{ borderBottom: "1px solid #F1F5F9" }} key={i}>
                  <td colSpan="3" style={{ color: "#1E293B" }}>
                    #{inv.orderID}
                  </td>
                  <td className="adminName" style={{ color: "#1E293B" }}>
                    {inv.userInfo ? inv?.userInfo.name : ""} <br />
                    <span style={{ fontSize: "80%" }}>
                      {inv?.userInfo?.email}
                    </span>
                    <span></span>
                  </td>
                  <td colSpan="3" style={{ color: "#1E293B" }}>
                    ${" "}
                    {parseFloat(
                      !isNaN(inv?.finalPrice) ? inv.finalPrice : 0
                    ).toFixed(2)}
                  </td>
                  <td style={{ color: "#1E293B" }}>
                    {deliveryType === "pickup"
                      ? moment(
                          new Date(
                            new Date(inv.createdAt).toLocaleString("en-US", {
                              timeZone:
                                localStorage.getItem("timezone") ??
                                "US/Central",
                            })
                          ).toISOString()
                        ).format("D MMM YYYY, h:mm A")
                      : timeDifferenceString(
                          new Date(inv.preferredDeliveryTime)
                        )}
                  </td>
                  <td className={checkColor(inv?.statusInfo?.displayName)}>
                    {/* {inv?.statusInfo?.displayName} */}
                    <div
                      className="newClass"
                      style={{
                        marginLeft: "-10px",
                        backgroundColor: `${backgroundColor(
                          inv?.statusInfo?.displayName
                        )}`,
                      }}>
                      <p
                        style={{
                          fontWeight: "400",
                          textAlign: "center",
                          fontSize: "13px",
                          paddingTop: "4px",
                          color: checkColor(inv?.statusInfo?.displayName),
                        }}>
                        {inv?.statusInfo?.displayName}
                        {/* {deliveryType === "pickup" ? inv?.statusInfo?.displayName : inv?.deliveryStatus} */}
                      </p>
                    </div>
                  </td>
                  <td>
                    <p
                      style={{
                        fontWeight: "400",
                        textAlign: "center",
                        fontSize: "13px",
                        paddingTop: "4px",
                      }}>
                      {!inv?.isPaymentApplicable
                        ? "N/A"
                        : inv?.paymentType === "EPAY_IN_STORE"
                        ? "In Store"
                        : "Online"}
                    </p>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0em",
                        justifyContent: "center",
                        alignItems: "center",
                      }}>
                      <button
                        style={{
                          border: "none",
                          backgroundColor: "white",
                          color: "#FFA382",
                        }}
                        className="btn btn-outline-primary btn-primary-hover"
                        onClick={() => {
                          localStorage.setItem(
                            "singleOrder",
                            JSON.stringify(inv)
                          );
                          window.oID = inv?.orderID;
                          history.push(`/typed-order-detail?_id=${inv?._id}`);
                        }}>
                        <i className="fas fa-eye"></i>{" "}
                      </button>
                      <i
                        onClick={() => {
                          setOrderToDelete(inv);
                        }}
                        style={{ color: "red", cursor: "pointer" }}
                        className="fas fa-trash"></i>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
